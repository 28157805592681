import React from "react";

export type SliderPaginationProps = {
    count: number;
    current: number;
    onChange: (i: number) => void;
    className?: string;
    overlay?: boolean;
};

export default function SliderPagination({
    count,
    current = 0,
    onChange, 
    overlay = false,
    className = ''
}: SliderPaginationProps) {
    return (
       <div className={`flex items-center justify-center py-4 ${className}`}>
        {Array.from('x'.repeat(count)).map((_, i) => {
          return <div key={i} onClick={() => onChange(i)} className={`${i === current ? 'w-12' : 'w-8 opacity-50'} ${overlay ? 'bg-white shadow-sm shadow-black' : 'bg-slate-500'} hover:opacity-75 cursor-pointer transition-all h-2 rounded-full mx-1`}></div>;
        })}
      </div>
    );
}