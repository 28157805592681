import { faEye, faGlasses } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { useLocale } from '../../hooks/useLocale';
import ImageCard from '../cards/ImageCard';
import DefaultGrid from '../grids/DefaultGrid';
import SectionHeader from '../header/SectionHeader';
import Translated from '../translation/Translated';
import Section, { SectionMeta } from './Section';

export type PressArchiveSectionProps = {
  meta: SectionMeta;
};

export function PressArchiveSection({ meta }: PressArchiveSectionProps) {
  const locale = useLocale();
  const data = useStaticQuery(graphql`
    query PressArchiveQuery {
      allStrapiPressArchive(sort: { fields: publicationDate, order: DESC }) {
        edges {
          node {
            publicationDate
            publicationName
            publicationUrl
            publicationLogo {
              ...StrapiImageConstrained
            }
            title
            locale
            strapi_id
            excerpt
          }
        }
      }
    }
  `);

  const press = data.allStrapiPressArchive.edges
    .filter(
      (edge: any) => edge.node.locale.toLowerCase() === locale.toLowerCase()
    )
    .map((edge: any) => edge.node);

  return (
    <Section appearance={meta.appearance}>
      <SectionHeader {...meta} />
      <DefaultGrid custom className="md:grid-cols-2 xl:grid-cols-3">
        {press.map((article) => {
          return (
            <ImageCard
              key={article.strapi_id}
              title={article.title}
              subtitle={`${article.publicationName} | ${
                new Date(article.publicationDate).getMonth() + 1
              }/${new Date(article.publicationDate).getFullYear()}`}
              description={article.excerpt}
              image={article.publicationLogo}
              containImage
              link={article.publicationUrl}
              cta={
                <>
                  <Translated k="readMore" />
                  <FontAwesomeIcon className="ml-2" icon={faGlasses} />
                </>
              }
              hideCtaArrow
            />
          );
        })}
      </DefaultGrid>
    </Section>
  );
}

export default PressArchiveSection;

export const query = graphql`
  fragment PressArchiveSection on STRAPI__COMPONENT_SECTIONS_PRESS_ARCHIVE {
    id
    strapi_component
    meta {
      ...BaseSection
      ...SectionHeader
    }
  }
`;
