import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql } from 'gatsby';
import React from 'react';
import { DarknessProvider } from '../../hooks/useDarkness';
import Button from '../buttons/Button';
import StrapiImage, { StrapiApiImage } from '../images/StrapiImage';

export type MainHeroSectionProps = {
  background: StrapiApiImage;
  icon?: StrapiApiImage;
  vignette?: boolean;
  title: string;
  introduction: string;
  cta: string;
  ctaLink?: string;
};

export function MainHeroSection({
  background,
  icon,
  vignette,
  title,
  introduction,
  cta,
  ctaLink,
}: MainHeroSectionProps) {
  return (
    <section className="min-h-screen relative flex justify-center md:items-center">
      <StrapiImage
        className="!absolute inset-0 z-0"
        image={background}
        loading="eager"
      />
      {vignette && (
        <div className="absolute inset-0 z-0 bg-gradient-to-t from-black"></div>
      )}
      <div className="relative md:-mt-14 z-10 text-white text-center md:text-xl text-lg max-w-prose px-4 py-20">
        {icon && (
          <StrapiImage
            className="md:w-20 md:h-20 md:block hidden w-14 h-14 mb-12 mx-auto"
            loading="eager"
            image={icon}
          />
        )}
        <h1 className="uppercase font-bold md:text-5xl text-2xl tracking-[0.2em] leading-snug mb-8">
          {title}
        </h1>
        <p className=" mx-auto font-medium leading-relaxed mb-12">
          {introduction}
        </p>
        <Button
          type="custom"
          className="inline-block bg-white border-white text-black text-lg scale-100 hover:scale-105 active:scale-95 transition-transform"
          link={ctaLink}
          title={`${cta}: ${title}`}
        >
          {cta}
          <FontAwesomeIcon className="sm:ml-12 ml-6" icon={faArrowRight} />
        </Button>
      </div>
    </section>
  );
}

export default MainHeroSection;

export const query = graphql`
  fragment MainHeroSection on STRAPI__COMPONENT_SECTIONS_MAIN_HERO {
    id
    strapi_component
    icon {
      ...StrapiImageConstrained
    }
    background {
      ...StrapiImageFullWidth
    }
    title
    introduction
    cta
    ctaLink
    vignette
  }
`;
