import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';
import StrapiImage, { StrapiApiImage } from '../images/StrapiImage';
import Link from '../link/Link';
import Translated from '../translation/Translated';

export type ImageCardProps = {
  title: string;
  subtitle?: string;
  description?: string;
  image?: StrapiApiImage;
  cta?: ReactNode;
  link?: string;
  containImage?: boolean;
  hideCtaArrow?: boolean;
};

export function ImageCard({
  title,
  subtitle,
  description,
  image,
  containImage = false,
  cta = <Translated k="readMore" />,
  link,
  hideCtaArrow = false,
}: ImageCardProps) {
  return (
    <div className="w-full flex flex-col text-slate-500 dark:text-slate-400 bg-white dark:bg-slate-900 shadow-xl dark:border-2 border-slate-800 rounded-xl overflow-hidden">
      <div className={containImage ? 'sm:p-8 p-4 flex justify-center' : ''}>
        {image && (
          <StrapiImage
            objectFit={containImage ? 'contain' : 'cover'}
            image={image}
            className="sm:h-48"
          />
        )}
      </div>
      <div className="sm:p-8 p-4 flex-1 flex flex-col">
        <div className="mb-auto">
          <h3
            className={`text-lg text-black dark:text-white font-bold ${
              description ? 'mb-2' : ''
            }`}
          >
            {title}
          </h3>
          {subtitle && (
            <div className="mb-2 text-slate-400 dark:text-slate-600">
              {subtitle}
            </div>
          )}
          {description && (
            <p>
              {description.split('\n').map((txt) => (
                <>
                  {txt}
                  <br />
                </>
              ))}
            </p>
          )}
        </div>
        {link && (
          <Link
            to={link}
            className="inline-block text-sm w-max mt-8 uppercase py-1.5 px-4 font-bold tracking-wide bg-slate-200 text-slate-600 hover:bg-slate-300 dark:bg-slate-800 dark:text-slate-400 dark:hover:bg-slate-700 transition-colors cursor-pointer rounded-md"
          >
            {cta}{' '}
            {!hideCtaArrow && (
              <FontAwesomeIcon className="ml-1" icon={faArrowRight} />
            )}
          </Link>
        )}
      </div>
    </div>
  );
}

export default ImageCard;
