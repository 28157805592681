import React from "react";
import { useDarkness } from "../../hooks/useDarkness";
import Link, { LinkProps } from "../link/Link";
import Card, { CardProps } from "./Card";

export type LinkCardProps = {
    
} & CardProps & LinkProps<any>;

export default function LinkCard({
    to,
    target,
    children,
    className = '',
    ...props
}: LinkCardProps) {
    return (
        <Link to={to} target={target} className="flex">
            <Card {...props} className={`hover:bg-slate-300 dark:hover:bg-slate-700 ${className}`}>{children}</Card>
        </Link>
    );
}