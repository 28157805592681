import { faArrowRight, IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode } from "react";
import { useDarkness } from "../../hooks/useDarkness";
import { LinkProps } from "../link/Link";
import Card from "./Card";
import LinkCard from "./LinkCard";

export type TeaserCardProps = {
    link?: string,
    icon?: IconDefinition,
    title: string,
    description?: string
};

export default function TeaserCard({
    link,
    icon,
    title,
    description
}: TeaserCardProps) {
    const inner = <>
        {icon && <FontAwesomeIcon className={`text-3xl text-slate-600 dark:text-slate-400 sm:mb-8 mb-4`} icon={icon} />}
        <div className={`${link ? 'mb-8' : ''}`}>
            <h3 className={`text-lg font-bold ${description ? 'mb-2' : ''}`}>{title}</h3>
            {description && <p className={`text-sm text-slate-700 dark:text-slate-400`}>{description}</p>}
        </div>
        {link && <FontAwesomeIcon className="mt-auto" icon={faArrowRight} />}
    </>;

    const cardClassName = "flex flex-col sm:p-8 p-4 items-start";

    if(!link) {
        return (
            <Card className={cardClassName}>
                {inner}
            </Card>
        );
    }

    return (
        <LinkCard to={link} className={cardClassName}>
            {inner}
        </LinkCard>
    );
}