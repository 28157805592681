import React, { ReactNode, useRef, Children, useState, useEffect, useCallback, TouchEvent } from "react";
import Slide from "./Slide";
import SliderPagination from "./SliderPagination";
import throttle from 'lodash/throttle';

export type SliderProps = {
    children: ReactNode;
    className?: string;
    fillParent?: boolean;
    orientation?: 'landscape'|'portrait';
};

export default function Slider({
    children,
    className = '',
    fillParent = false,
    orientation = 'landscape'
}: SliderProps) {
    const sliderRef = useRef<HTMLDivElement>();
    const trackRef = useRef<HTMLDivElement>();
    const slideCount = Children.count(children);

    const [width, setWidth] = useState<number>(0);
    const [current, setCurrent] = useState<number>(0);

    const [isDragging, setIsDragging] = useState(false);
    const touchStart = useRef({x:0,y:0});
    const previousCurrent = useRef(0);

    const layout = () => {
        const computedStyles = window.getComputedStyle(sliderRef.current);
        const paddingX = parseFloat(computedStyles.paddingLeft) + parseFloat(computedStyles.paddingRight);
        setWidth(sliderRef.current.clientWidth - paddingX);

        let maxHeight = 0;

        for(let i = 0; i < trackRef.current.children.length; i++) {
            const item = trackRef.current.children.item(i);
            maxHeight = Math.max(maxHeight, item.clientHeight);
        }
    };

    const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
        touchStart.current = {x: e.touches[0].clientX, y: e.touches[0].clientY};
        previousCurrent.current = current;
        setIsDragging(true);
    }

    const handleTouchMove = useCallback(throttle((e: TouchEvent<HTMLDivElement>) => {
        if(!isDragging) return;

        const x = e.touches[0].clientX;
        const y = e.touches[0].clientY;
        const deltaX = x - touchStart.current.x;
        const deltaY = y - touchStart.current.y;

        if(Math.abs(deltaX) > 20 && Math.abs(deltaY) < 40) {
            const newSlideIdx = Math.max(0,Math.min(current - Math.sign(deltaX), slideCount - 1));
            setCurrent(newSlideIdx);
            previousCurrent.current = newSlideIdx;
            setIsDragging(false);
        }
    }, 100), [isDragging]);

    const handleTouchEnd = (e: TouchEvent<HTMLDivElement>) => {
        setIsDragging(false);
        setCurrent(previousCurrent.current);
    }

    useEffect(() => {
        window.addEventListener('resize', layout);
        layout();

        return () => {
            window.removeEventListener('resize', layout);
        }
    }, []);

    const heightStyle = !fillParent ? {height: orientation === 'landscape' ? width/16*10 : width/9*12} : {};

    return (
        <>
        <div className={className}>
            <div onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd} onTouchCancel={handleTouchEnd} ref={sliderRef} style={heightStyle} className={`${fillParent ? 'absolute inset-0' : ''} overflow-hidden transform-gpu transition-all duration-500`}>
                <div ref={trackRef} style={{width: slideCount * width, transform: `translate(${-current * width}px, 0)`}} className={`absolute h-full transition-all ${isDragging ? '' : 'duration-500'}`}>
                    {Children.map(children, (child, idx) => <Slide style={{width}} key={idx} className={`float-left h-full`}>{child}</Slide>)}
                </div>
            </div>
            {fillParent && <SliderPagination overlay className="absolute bottom-0 inset-x-0 z-10" count={slideCount} current={current} onChange={(i) => setCurrent(i)} />}
        </div>
        {!fillParent && <SliderPagination className="relative z-10" count={slideCount} current={current} onChange={(i) => setCurrent(i)} />}
        </>
    );
}