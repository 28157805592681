import { graphql } from "gatsby";
import React from "react";
import StatCard, { StatCardProps } from "../cards/StatCard";
import LinkTeaser, { LinkTeaserProps } from "../teaser/LinkTeaser";
import DefaultGrid, { DefaultGridProps } from "./DefaultGrid";

type StatGridStat = {
    id: string
} & StatCardProps;

export type StatGridProps = {
    stats: StatGridStat[];
} & Omit<DefaultGridProps, 'children'>;

export default function StatGrid({stats, ...gridProps}: StatGridProps) {
    return (
        <DefaultGrid {...gridProps}>
            {stats.map(({id, ...stat}) => {
                return <StatCard key={id} {...stat} />
            })}
        </DefaultGrid>
    );
}

export const query = graphql`
    fragment StatGridStat on STRAPI__COMPONENT_COMPONENTS_STATISTIC {
        id
        value
        dimension
        description
    }
`;