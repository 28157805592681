import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql } from 'gatsby';
import React from 'react';
import { useDarkness } from '../../hooks/useDarkness';
import usePreferredColorSchema from '../../hooks/usePreferredColorSchema';
import Button from '../buttons/Button';
import StrapiImage, { StrapiApiImage } from '../images/StrapiImage';

export type HeroSectionProps = {
  image: StrapiApiImage;
  title: string;
  introduction: string;
  cta: string;
  ctaLink: string;
  backgroundColor: string;
  backgroundColorDark: string;
  textColor: string;
  textColorDark: string;
  reversed: boolean;
};

export function HeroSection({
  image,
  title,
  introduction,
  cta,
  ctaLink,
  backgroundColor,
  backgroundColorDark,
  textColor,
  textColorDark,
  reversed,
}: HeroSectionProps) {
  const darkness = usePreferredColorSchema();

  const sectionStyle =
    darkness === 'dark'
      ? { backgroundColor: backgroundColorDark, color: textColorDark }
      : { backgroundColor, color: textColor };

  const buttonStyle =
    darkness === 'dark'
      ? {
          color: backgroundColorDark,
          backgroundColor: textColorDark,
          borderColor: textColorDark,
        }
      : {
          color: backgroundColor,
          backgroundColor: textColor,
          borderColor: textColor,
        };

  return (
    <section style={sectionStyle} className="min-h-[50vh] flex py-20">
      <div className="container m-auto sm:px-8 px-4 flex lg:flex-row flex-col justify-between items-center">
        <div className="md:text-xl text-lg text-center lg:text-left max-w-prose px-4 sm:py-16 py-8 flex-1">
          <h2 className="uppercase font-bold md:text-5xl text-2xl tracking-[0.2em] leading-snug mb-8">
            {title}
          </h2>
          <p className=" mx-auto leading-relaxed mb-12">{introduction}</p>
          {ctaLink && (
            <Button
              style={buttonStyle}
              type="custom"
              className="inline-block text-lg scale-100 hover:scale-105 active:scale-95 transition-transform"
              link={ctaLink}
              title={`${cta}: ${title}`}
            >
              {cta}
              <FontAwesomeIcon className="sm:ml-12 ml-6" icon={faArrowRight} />
            </Button>
          )}
        </div>
        <div
          className={`flex-1 -order-1 ${
            reversed ? '' : 'lg:-order-none'
          }  lg:py-10 py-4 px-8 flex justify-center items-center`}
        >
          <StrapiImage
            className="lg:max-h-[40rem] max-h-80 w-auto"
            image={image}
            objectFit="contain"
          />
        </div>
      </div>
    </section>
  );
}

export default HeroSection;

export const query = graphql`
  fragment HeroSection on STRAPI__COMPONENT_SECTIONS_HERO_SECTION {
    id
    strapi_component
    image {
      ...StrapiImageConstrained
    }
    title
    introduction
    cta
    ctaLink
    backgroundColor
    backgroundColorDark
    textColor
    textColorDark
    reversed
  }
`;
