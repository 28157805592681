import { graphql } from "gatsby";
import React from "react";
import LinkTeaser, { LinkTeaserProps } from "../teaser/LinkTeaser";
import DefaultGrid, { DefaultGridProps } from "./DefaultGrid";

export type ReferenceLink = {
    id: string
} & LinkTeaserProps;

export type LinkGridProps = {
    links: ReferenceLink[];
} & Omit<DefaultGridProps, 'children'>;

export default function LinkGrid({links, ...gridProps}: LinkGridProps) {
    return (
        <DefaultGrid {...gridProps}>
            {links.map(({id, ...link}) => {
                return <LinkTeaser key={id} {...link} />
            })}
        </DefaultGrid>
    );
}

export const query = graphql`
    fragment LinkGridLink on STRAPI__COMPONENT_COMPONENTS_REFERENCE {
        id
        title
        link
        description
        appearance
    }
`;