import { graphql } from 'gatsby';
import * as React from 'react';
import PageHeader from '../../components/sections/PageHeader';
import SectionRenderer from '../../components/sections/SectionRenderer';

const Page = ({ data }) => {
  return (
    <>
      <PageHeader {...data.strapiPage.header} />
      <SectionRenderer sections={data.strapiPage?.sections} />
    </>
  );
};

export const query = graphql`
  query ($id: String) {
    strapiPage(id: { eq: $id }) {
      title
      excerpt
      locale
      slug
      localizations {
        data {
          attributes {
            locale
            slug
          }
        }
      }
      meta {
        ...SiteMeta
      }
      header {
        ...PageHeader
      }
      ...PageSectionRenderer
    }
  }
`;

export default Page;
