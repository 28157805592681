import React from 'react';
import StrapiImage, { StrapiApiImage } from './StrapiImage';
import { useDarkness } from '../../hooks/useDarkness';
import Slider from '../slider/Slider';
import Masonry from './Masonry';

export type MultiImageRendererProps = {
  images: StrapiApiImage[];
  type?: 'grid' | 'slide';
  full?: boolean;
  className?: string;
};

export default function MultiImageRenderer({
  images,
  className = '',
  type = 'slide',
  full = false,
}: MultiImageRendererProps) {
  const darkness = useDarkness();

  if (!images || images.length === 0) return null;

  if (images.length === 1) {
    return <StrapiImage image={images[0]} className={className} />;
  }

  if (type === 'grid') {
    return <Masonry images={images} fillParent={full} className={className} />;
  }

  if (type === 'slide') {
    const orientations = images
      .map((img) => (img.width > img.height ? 'landscape' : 'portrait'))
      .reduce(
        (prev, current) => {
          prev[current]++;
          return prev;
        },
        { landscape: 0, portrait: 0 }
      );

    return (
      <Slider
        fillParent={full}
        orientation={
          orientations.landscape > orientations.portrait
            ? 'landscape'
            : 'portrait'
        }
        className={`${className}`}
      >
        {images.map((img) => {
          return (
            <StrapiImage
              key={img.id}
              image={img}
              className={'!absolute inset-0'}
            />
          );
        })}
      </Slider>
    );
  }
}
