import { faGlasses } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { slugify } from '../../helpers/makePath';
import { useLocale } from '../../hooks/useLocale';
import ImageCard from '../cards/ImageCard';
import DefaultGrid from '../grids/DefaultGrid';
import SectionHeader from '../header/SectionHeader';
import Translated from '../translation/Translated';
import Section, { SectionMeta } from './Section';

export type JobOffersSectionProps = {
  meta: SectionMeta;
};

export function JobOffersSection({ meta }: JobOffersSectionProps) {
  const locale = useLocale();
  const data = useStaticQuery(graphql`
    query JobOfferQuery {
      allStrapiJobOffer(sort: { fields: updatedAt, order: DESC }) {
        nodes {
          location
          title
          time
          strapi_id
          excerpt
          locale
          image {
            ...StrapiImageConstrained
          }
        }
      }
    }
  `);

  const jobOffers = data.allStrapiJobOffer.nodes.filter(
    (node: any) => node.locale.toLowerCase() === locale.toLowerCase()
  );

  return (
    <Section appearance={meta.appearance}>
      <SectionHeader {...meta} />
      <DefaultGrid>
        {jobOffers.map((offer) => {
          return (
            <ImageCard
              key={offer.strapi_id}
              title={offer.title}
              subtitle={`${offer.time} | ${offer.location}`}
              description={offer.excerpt}
              image={offer.image}
              link={`/${locale}/${slugify(offer.title)}`}
              cta={<Translated k="details" />}
            />
          );
        })}
      </DefaultGrid>
    </Section>
  );
}

export default JobOffersSection;

export const query = graphql`
  fragment JobOffersSection on STRAPI__COMPONENT_SECTIONS_JOB_LISTINGS {
    id
    strapi_component
    meta {
      ...BaseSection
      ...SectionHeader
    }
  }
`;
