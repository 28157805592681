import { graphql } from 'gatsby';
import React from 'react';
import ImageCard, { ImageCardProps } from '../cards/ImageCard';
import LinkTeaser, { LinkTeaserProps } from '../teaser/LinkTeaser';
import DefaultGrid, { DefaultGridProps } from './DefaultGrid';

export type SectionCard = {
  id: string;
} & ImageCardProps;

export type ImageCardGridProps = {
  cards: SectionCard[];
} & Omit<DefaultGridProps, 'children'>;

export default function ImageCardGrid({
  cards,
  ...gridProps
}: ImageCardGridProps) {
  return (
    <DefaultGrid {...gridProps}>
      {cards.map(({ id, ...card }) => {
        return <ImageCard key={id} {...card} />;
      })}
    </DefaultGrid>
  );
}

export const query = graphql`
  fragment ImageCard on STRAPI__COMPONENT_COMPONENTS_CARD {
    id
    title
    description
    cta
    image {
      ...StrapiImageConstrained
    }
    link
  }
`;
