import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import React, { ReactNode, useState } from "react";
import { useDarkness } from "../../hooks/useDarkness";
import CollapseItem from "./CollapseItem";

export type AccordeonItem = {
    id?: string,
    title: string,
    content: any
}

export type AccordeonProps = {
    items: AccordeonItem[],
    className?: string
};

export default function Accordeon({
    items,
    className
}: AccordeonProps) {
    const [current, setCurrent] = useState(null);

    return (
        <div className={`border-t-2 dark:border-slate-700 border-slate-300 ${className}`}>
            {items.map(({id, title, content}) => {
                return <CollapseItem key={id} open={id === current} onClick={() => id === current ? setCurrent(null) : setCurrent(id)} title={title}>
                    <MDXRenderer>{content.data.childMdx.body}</MDXRenderer>
                </CollapseItem>
            })}
        </div>
    );
}

export const query = graphql`
    fragment AccordeonCollapseItem on STRAPI__COMPONENT_COMPONENTS_COLLAPSE_ITEM {
        id
        title
        content {
            data {
                childMdx {
                    body
                }
            }
        }
    }
`;