import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import useMeasure from '../../hooks/useMeasure';
import Button from '../buttons/Button';
import { useSectionAppearance } from '../sections/Section';
import Translated from '../translation/Translated';

export type ReadMoreCollapseProps = {
  children: ReactNode;
  maxHeight?: number;
};

export default function ReadMoreCollapse({
  children,
  maxHeight = 400,
}: ReadMoreCollapseProps) {
  const [showReadMore, setShowReadMore] = useState(maxHeight > 0);
  const [collapsed, setCollapsed] = useState(true);

  const bodyRef = useRef<HTMLDivElement>();
  const [innerHeight, setInnerHeight] = useState(0);

  const section = useSectionAppearance();

  const onWindowChange = () => {
    setInnerHeight(bodyRef.current.clientHeight);
  };

  const handleReadMore = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  useEffect(() => {
    window.addEventListener('resize', onWindowChange);
    onWindowChange();
    return () => {
      window.removeEventListener('resize', onWindowChange);
    };
  }, []);

  useEffect(() => {
    if (maxHeight && innerHeight > maxHeight && !showReadMore) {
      setShowReadMore(true);
    } else {
      setShowReadMore(false);
    }
  }, [innerHeight, maxHeight]);

  if (!showReadMore) {
    return <div ref={bodyRef}>{children}</div>;
  }

  let backgroundFade = '';

  if (section.appearance === 'light' && section.darkness === 'light') {
    backgroundFade = 'from-slate-100';
  } else if (section.appearance === 'white' && section.darkness === 'light') {
    backgroundFade = 'from-white';
  } else if (section.appearance === 'dark' && section.darkness === 'light') {
    backgroundFade = 'from-slate-900';
  } else if (section.appearance === 'light' && section.darkness === 'dark') {
    backgroundFade = 'from-slate-900';
  } else if (section.appearance === 'white' && section.darkness === 'dark') {
    backgroundFade = 'from-slate-800';
  } else if (section.appearance === 'dark' && section.darkness === 'dark') {
    backgroundFade = 'from-black';
  }

  return (
    <div className="relative">
      <div
        style={{ height: collapsed ? maxHeight : innerHeight }}
        className="relative overflow-hidden transition-all duration-500"
      >
        <div ref={bodyRef} className="pb-6">
          {children}
        </div>
      </div>
      <div
        className={`absolute inset-x-0 bottom-16 h-20 bg-gradient-to-t ${backgroundFade} to-transparent ${
          collapsed ? 'opacity-100' : 'opacity-0'
        } transition-opacity`}
      />
      <div className="h-20 cursor-pointer flex justify-start items-center text-slate-600">
        <Button onClick={handleReadMore} small type="secondary">
          {collapsed ? (
            <Translated k="readMore" />
          ) : (
            <Translated k="readLess" />
          )}{' '}
          <FontAwesomeIcon
            icon={faChevronDown}
            className={`ml-2 ${
              collapsed ? '' : 'rotate-180'
            } transition-transform`}
          />
        </Button>
      </div>
    </div>
  );
}
