import React from "react";
import StrapiImage, { StrapiApiImage } from "./StrapiImage";

export type MasonryProps = {
    images: StrapiApiImage[];
    className: string;
    fillParent?: boolean;
};

export default function Masonry({
    images,
    className = '',
    fillParent
}: MasonryProps) {
    return (
        <div className={`grid grid-cols-2 ${fillParent ? '' : 'gap-8'} ${fillParent ? className : ''}`}>
            {images.map((img) => {
                return <StrapiImage key={img.id} image={img} className={`${img.width > img.height ? 'col-span-2' : ''} ${fillParent ? '' : className}`} />
            })}
        </div>
    );
}