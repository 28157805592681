import { graphql } from 'gatsby';
import React from 'react';
import LinkGrid, { LinkGridProps } from '../grids/LinkGrid';
import Section, { SectionMeta } from './Section';
import { StrapiApiImage } from '../images/StrapiImage';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import SectionHeader from '../header/SectionHeader';
import MultiImageRenderer from '../images/MultiImageRenderer';
import StatGrid, { StatGridProps } from '../grids/StatGrid';
import Accordeon, { AccordeonItem } from '../collapse/Accordeon';
import ReadMoreCollapse from '../collapse/ReadMoreCollapse';
import ImageCardGrid, { ImageCardGridProps } from '../grids/ImageCardGrid';

export type BasicSectionImages = {
  position: 'left' | 'right';
  appearance: 'full' | 'boxed' | 'flat';
  multiple: 'grid' | 'slide';
  images: StrapiApiImage[];
};

export type BasicSectionProps = {
  meta: SectionMeta;
  childStrapiComponentSectionsBasicSectionContentTextnode: any;
  links?: LinkGridProps['links'];
  stats?: StatGridProps['stats'];
  images?: BasicSectionImages;
  cards?: ImageCardGridProps['cards'];
  collapse_items?: AccordeonItem[];
  collapseContent?: boolean;
};

export default function BasicSection({
  meta,
  childStrapiComponentSectionsBasicSectionContentTextnode,
  links,
  stats,
  images,
  collapse_items,
  collapseContent = false,
  cards,
}: BasicSectionProps) {
  const content =
    childStrapiComponentSectionsBasicSectionContentTextnode?.childMdx?.body;
  const imageSectionContent = (
    <>
      {content && (
        <ReadMoreCollapse maxHeight={collapseContent ? 400 : 0}>
          <MDXRenderer>{content}</MDXRenderer>
        </ReadMoreCollapse>
      )}
      {stats?.length > 0 && (
        <StatGrid className="md:mt-16 mt-8" stats={stats} narrow />
      )}
      {collapse_items?.length > 0 && (
        <Accordeon className="md:mt-16 mt-8" items={collapse_items} />
      )}
      {cards?.length > 0 && (
        <ImageCardGrid className="md:mt-16 mt-8" cards={cards} narrow />
      )}
      {links?.length > 0 && (
        <LinkGrid className="md:mt-16 mt-8" links={links} narrow />
      )}
    </>
  );

  if (images) {
    if (images.appearance === 'full') {
      return (
        <Section appearance={meta.appearance} full>
          <MultiImageRenderer
            full
            type={images.multiple}
            className={`lg:h-auto sm:h-80 h-40 lg:!absolute relative lg:inset-y-0 ${
              images.position === 'left'
                ? 'lg:left-0 lg:right-1/2 lg:mr-8'
                : 'lg:right-0 lg:left-1/2 lg:ml-8'
            } overflow-hidden`}
            images={images.images}
          />
          <div className="container m-auto sm:px-8 px-4 py-16">
            <div className="grid lg:grid-cols-2 lg:gap-16">
              <div
                className={`${
                  images.position === 'left' ? 'order-2' : 'order-1'
                }`}
              >
                <SectionHeader {...meta} />
                {imageSectionContent}
              </div>
              <div
                className={`${
                  images.position === 'left' ? 'order-1' : 'order-2'
                }`}
              />
            </div>
          </div>
        </Section>
      );
    }

    return (
      <Section appearance={meta.appearance}>
        <SectionHeader {...meta} />
        <div className="grid lg:grid-cols-2 gap-16">
          <div
            className={`${images.position === 'left' ? 'order-2' : 'order-1'}`}
          >
            {imageSectionContent}
          </div>
          <div
            className={`${images.position === 'left' ? 'order-1' : 'order-2'}`}
          >
            <MultiImageRenderer
              type={images.multiple}
              className={`${
                images.appearance === 'flat' ? '' : 'rounded-xl shadow-xl'
              } overflow-hidden`}
              images={images.images}
            />
          </div>
        </div>
      </Section>
    );
  }

  return (
    <Section appearance={meta.appearance}>
      <SectionHeader {...meta} />
      {content && (
        <div className="lg:columns-2 gap-16">
          <ReadMoreCollapse maxHeight={collapseContent ? 400 : 0}>
            <MDXRenderer>{content}</MDXRenderer>
          </ReadMoreCollapse>
        </div>
      )}
      {stats?.length > 0 && (
        <StatGrid className="md:mt-16 mt-8" stats={stats} />
      )}
      {cards?.length > 0 && (
        <ImageCardGrid className="md:mt-16 mt-8" cards={cards} />
      )}
      {collapse_items?.length > 0 && (
        <Accordeon className="md:mt-16 mt-8" items={collapse_items} />
      )}
      {links?.length > 0 && (
        <LinkGrid className="md:mt-16 mt-8" links={links} />
      )}
    </Section>
  );
}

export const query = graphql`
  fragment BasicSection on STRAPI__COMPONENT_SECTIONS_BASIC_SECTION {
    id
    strapi_component
    meta {
      ...BaseSection
      ...SectionHeader
    }
    collapseContent
    childStrapiComponentSectionsBasicSectionContentTextnode {
      childMdx {
        body
      }
    }
    links {
      ...LinkGridLink
    }
    images {
      position
      appearance
      multiple
      images {
        ...StrapiImageFullWidth
      }
    }
    stats {
      ...StatGridStat
    }
    collapse_items {
      ...AccordeonCollapseItem
    }
    cards {
      ...ImageCard
    }
  }
`;
