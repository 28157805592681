import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { graphql } from "gatsby";
import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useDarkness } from "../../hooks/useDarkness";

export type CollapseItemProps = {
    title: string,
    children: ReactNode,
    open?: boolean,
    onClick?: () => void
};

export default function CollapseItem({
    title,
    children,
    open = false,
    onClick
}: CollapseItemProps) {
    const bodyRef = useRef<HTMLDivElement>();
    const [innerHeight, setInnerHeight] = useState(0);

    const onWindowChange = () => {
        setInnerHeight(bodyRef.current.clientHeight);
    };

    useEffect(() => {
        window.addEventListener('resize', onWindowChange);
        onWindowChange();
        return () => {
            window.removeEventListener('resize', onWindowChange);
        }
    }, []);

    return (
        <div className={`border-b-2 dark:border-slate-700 border-slate-300`}>
            <div className="flex justify-between items-center py-6 cursor-pointer" onClick={onClick}>
                <h3 className={`text-lg font-bold mr-4`}>{title}</h3>
                <FontAwesomeIcon icon={faChevronDown} className={`text-lg text-slate-500 transition-all ${open ? 'rotate-180' : ''} duration-300`} />
            </div>
            <div style={{maxHeight: open ? innerHeight : 0}} className={`overflow-hidden ease transition-all duration-300`}>
                <div ref={bodyRef} className="pb-6">
                    {children}
                </div>
            </div>
        </div>
    );
}