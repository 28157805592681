import { graphql } from 'gatsby';
import React from 'react';
import BasicSection, { BasicSectionProps } from './BasicSection';
import HeroSection from './HeroSection';
import JobOffersSection from './JobOffersSection';
import MainHeroSection from './MainHeroSection';
import PressArchiveSection from './PressArchiveSection';
import { SectionMeta } from './Section';

export type Section = {
  id: number;
  meta: SectionMeta;
} & ({
  strapi_component: 'sections.basic-section';
} & BasicSectionProps);

export type SectionRendererProps = {
  sections: Section[];
};

export default function SectionRenderer({ sections }: SectionRendererProps) {
  return (
    <>
      {sections?.map((section) => {
        if (section.strapi_component === 'sections.basic-section') {
          return <BasicSection key={section.id} {...section} />;
        }
        if (section.strapi_component === 'sections.press-archive') {
          return <PressArchiveSection key={section.id} {...section} />;
        }
        if (section.strapi_component === 'sections.job-listings') {
          return <JobOffersSection key={section.id} {...section} />;
        }
        if (section.strapi_component === 'sections.main-hero') {
          return <MainHeroSection key={section.id} {...section} />;
        }
        if (section.strapi_component === 'sections.hero-section') {
          return <HeroSection key={section.id} {...section} />;
        }
      })}
    </>
  );
}

export const query = graphql`
  fragment PageSectionRenderer on STRAPI_PAGE {
    sections {
      ...BasicSection
      ...PressArchiveSection
      ...JobOffersSection
      ...MainHeroSection
      ...HeroSection
    }
  }
`;
