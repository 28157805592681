import React, { CSSProperties, ReactNode } from "react";

export type SlideProps = {
    children: ReactNode;
    className?: string;
    style?: CSSProperties;
};

export default function Slide({
    children,
    className = '',
    style
}: SlideProps) {
    return (
        <div style={style} className={`relative ${className}`}>
            {children}
        </div>
    );
}