import { IconDefinition } from "@fortawesome/free-regular-svg-icons";
import { faArrowUpRightFromSquare, faFileArrowDown, faFilePdf, faSquareUpRight } from "@fortawesome/pro-light-svg-icons";
import React from "react";
import TeaserCard from "../cards/TeaserCard";

export type LinkTeaserAppearance = 'link' | 'download' | 'pdf';

export type LinkTeaserProps = {
    title: string,
    link: string,
    description?: string,
    appearance: LinkTeaserAppearance
};

const mapIcon = (appearance: LinkTeaserAppearance): IconDefinition => {
    switch (appearance) {
        case 'download': 
            return faFileArrowDown;
        case 'pdf':
            return faFilePdf;
        default:
            return faSquareUpRight;
    }
}

export default function LinkTeaser({
    title,
    link,
    description,
    appearance
}: LinkTeaserProps) {
   return <TeaserCard link={link} icon={mapIcon(appearance)} title={title} description={description} />;
}