import React from "react";
import { useDarkness } from "../../hooks/useDarkness";
import Card from "./Card";

export type StatCardProps = {
    value: string,
    dimension: string,
    description?: string,
};

export default function StatCard({
    value,
    dimension,
    description,
}: StatCardProps) {
    return (
        <Card flat className={`border-l-4 border-l-slate-300 dark:border-l-slate-700 pl-4 mb-4`}>
            <div className={`sm:text-4xl text-2xl font-bold sm:mb-2 mb-1`}>{value}</div>
            <div className={`text-sm text-slate-600 dark:text-slate-400`}><strong className="font-bold sm:text-base">{dimension}</strong><br/>{description}</div>
        </Card>
    );
}